import React, { useState, useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from './styles/artists.module.scss'

export default function Artists() {

  return (
    <Layout pageclass='artists'>
      <Seo title="Artists" />
      <StaticQuery
        query={graphql`
          query Artists {
            allContentfulArtist {
              edges {
                node {
                  slug
                  artistName
                  featuredImage {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
        `}
        render={result => {
        const data = result.allContentfulArtist.edges

          return (
            <div style={{marginTop: '116px', minHeight: `calc(100vh - 560px)`}}>
              {data.map((artist, index) => <Title key={index} data={artist}/>)}
            </div>
          )
        }}
      />
    </Layout>
  )
}

const Title = ({data}) => {
  const [isImageVisible, setIsImageVisible] = useState(false)

  return (
    <div className={styles.root}>
      <Link
        className={styles.link}
        to={data.node.slug}
        onMouseEnter={() => setIsImageVisible(true)}
        onMouseLeave={() => setIsImageVisible(false)}
      >
        {data.node.artistName}
      </Link>
      <div className={`${styles.imageContainer} grid-parent`}>
        <div className={styles.imageContainerInner}>
          <img className={`${styles.featuredImage} ${isImageVisible ? styles.isVisible : null}`} src={data.node.featuredImage.file.url} />
        </div>
      </div>
    </div>
  )
}
